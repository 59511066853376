import { createSelector } from '@ngrx/store';
import { chain, compact, filter, forEach, isEmpty, map } from 'lodash';
import { HW_ID_REGEX, NETWORK_SIDE, WAN_INTERFACES } from '@shared/constants/constants';
import { NetworkInterface } from '../models/shared.model';
import { getBridgeInterfaceFeatureState } from './bridge-interface-configuration.selector';
import { getHardwareInterfaceFeatureState } from './hardware-interface-configuration.selector';
import { getCurrentDeviceQoSClasses } from './qos-configuration.selector';
import { getCurrentTrafficSteering } from './traffic-steering-configuration.selector';
import { getTunnelInterfaceFeatureState } from './tunnel-interface-configuration.selector';
import { getVlanInterfaceFeatureState } from './vlan-interface-configuration.selector';
import { getIpsecInterfaceFeatureState } from './ipsec-interface.selector';
import { getPrismaInterfaceFeatureState } from './prisma-interface.selector';

export const getNetworkInterfaces = createSelector(
  getHardwareInterfaceFeatureState,
  getTunnelInterfaceFeatureState,
  getBridgeInterfaceFeatureState,
  getVlanInterfaceFeatureState,
  (hardwareInterfaceState, tunnelInterfaceState, bridgeInterfaceState, vlanInterfaceState) => {
    return [
      ...hardwareInterfaceState.interfaces,
      ...tunnelInterfaceState.interfaces,
      ...bridgeInterfaceState.interfaces,
      ...vlanInterfaceState.interfaces,
    ];
  }
);

export const getNetworkInterfacesWithIpsecAndPrisma = createSelector(
  getNetworkInterfaces,
  getIpsecInterfaceFeatureState,
  getPrismaInterfaceFeatureState,
  (getNetworkInterfaces, ipsecInterfaceState, prismaInterfaceState) => {
    return [
      ...getNetworkInterfaces,
      ...ipsecInterfaceState.interfaces,
      ...prismaInterfaceState.interfaces,
    ];
  }
);

export const getAllInterfacesUserIPAddress = createSelector(
  getNetworkInterfaces,
  (networkInterfaces) => {
    return compact(
      networkInterfaces.flatMap((currentInterface) =>
        chain(currentInterface.user_defined_ip_addresses).split('/').head().value()
      )
    );
  }
);

export const getLANInterfaces = createSelector(getNetworkInterfaces, (networkInterfaces) => {
  const filteredInterfaces = filter(networkInterfaces, { network_side: 'LAN' });
  return filteredInterfaces.map((filteredInterface) => ({
    id: filteredInterface.id,
    value: filteredInterface.user_defined_name,
  }));
});

export const getLANInterfacesWithIPAddresses = createSelector(
  getNetworkInterfaces,
  (networkInterfaces) => {
    const isLanWithIPAddress = (networkInterface: NetworkInterface) =>
      networkInterface.network_side === 'LAN' &&
      networkInterface.system_defined_ip_addresses &&
      networkInterface.system_defined_ip_addresses.length !== 0;

    const filteredInterfaces: any[] = networkInterfaces.filter(isLanWithIPAddress);

    return filteredInterfaces.map((filteredInterface) => ({
      id: filteredInterface.id,
      value: `${
        filteredInterface.user_defined_name
      } (${filteredInterface.system_defined_ip_addresses.toString()})`,
      system_defined_ip_addresses: filteredInterface.system_defined_ip_addresses,
      system_defined_name: filteredInterface.system_defined_name,
    }));
  }
);

export const getAllNetworkInterfaces = createSelector(
  getNetworkInterfacesWithIpsecAndPrisma,
  (networkInterfaces) => {
    return networkInterfaces.map((filteredInterface) => ({
      id: filteredInterface.id,
      value: filteredInterface.user_defined_name,
    }));
  }
);

export const getHardwareVlanBridgeLANInterfaces = createSelector(
  getHardwareInterfaceFeatureState,
  getBridgeInterfaceFeatureState,
  getVlanInterfaceFeatureState,
  (hardwareInterfaceState, bridgeInterfaceState, vlanInterfaceState) => {
    const networkInterfaces = [
      ...hardwareInterfaceState.interfaces,
      ...bridgeInterfaceState.interfaces,
      ...vlanInterfaceState.interfaces,
    ];
    const lanInterfaces = filter(networkInterfaces, { network_side: NETWORK_SIDE.LAN });
    return lanInterfaces;
  }
);

export const getWANInterfacesWithIpsecAndPrisma = createSelector(
  getNetworkInterfacesWithIpsecAndPrisma,
  (networkInterfaces) => {
    return chain(networkInterfaces)
      .filter({ network_side: 'WAN' })
      .orderBy(['is_configured'], ['desc'])
      .map((interfaceItem) => ({
        id: interfaceItem.id,
        value: interfaceItem.user_defined_name,
        isConfigured:
          interfaceItem.is_configured === undefined ? true : interfaceItem.is_configured,
      }))
      .value();
  }
);

export const getWANAndLANInterfaces = createSelector(
  getNetworkInterfacesWithIpsecAndPrisma,
  (networkInterfaces) => {
    return chain(networkInterfaces)
      .orderBy(['is_configured'], ['desc'])
      .map((interfaceItem) => ({
        id: interfaceItem.id,
        value: interfaceItem.user_defined_name + ` (${interfaceItem.network_side})`,
        isConfigured:
          interfaceItem.is_configured === undefined ? true : interfaceItem.is_configured,
      }))
      .value();
  }
);

export const networkInterfaceMembers = createSelector(
  getHardwareInterfaceFeatureState,
  getVlanInterfaceFeatureState,
  (hardwareInterfaceState, vlanInterfaceState) => {
    const interfaceMembers = [
      ...hardwareInterfaceState.interfaces,
      ...vlanInterfaceState.interfaces,
    ];
    return interfaceMembers;
  }
);

export const getBridgeMembers = createSelector(networkInterfaceMembers, (interfaceMembers) => {
  const filteredMembers = filter(
    interfaceMembers,
    (interfaceMember) =>
      isEmpty(interfaceMember.bridge_id) && isEmpty(interfaceMember.dhcp_server_configurations)
  );
  return filteredMembers.map((interfaceMember: any) => {
    return {
      id: interfaceMember.id,
      value: interfaceMember.user_defined_name,
    };
  });
});

export const getHardwareInterfaces = createSelector(
  getHardwareInterfaceFeatureState,
  (hardwareState) => {
    return hardwareState.interfaces.map((hardwareInterface) => {
      return {
        id: hardwareInterface.id,
        value: hardwareInterface.user_defined_name,
      };
    });
  }
);

export const getBridges = createSelector(getBridgeInterfaceFeatureState, (bridgeState) => {
  return bridgeState.interfaces.map((bridge) => {
    return {
      id: bridge.id,
      value: bridge.user_defined_name,
    };
  });
});

export const getBridgeInterfaces = createSelector(
  getBridgeInterfaceFeatureState,
  (bridgeInterfaceState) => {
    return map(bridgeInterfaceState.interfaces, (bridgeInterface) => {
      return {
        id: bridgeInterface.id,
        value: bridgeInterface.user_defined_name,
      };
    });
  }
);

export const getCurrentBridgeInterfaces = createSelector(
  getBridgeInterfaceFeatureState,
  (bridgeInterfaceState) => bridgeInterfaceState.interfaces
);

export const getMappedBridge = createSelector(
  networkInterfaceMembers,
  getCurrentDeviceQoSClasses,
  getCurrentTrafficSteering,
  (interfaceMembers, qosClasses, trafficSteerings, props) => {
    const mappedBridges: any[] = [];
    forEach(interfaceMembers, (interfaceMember) => {
      if (interfaceMember.bridge_id === props.bridgeId) {
        mappedBridges.push({
          type: interfaceMember.id.match(HW_ID_REGEX) ? 'Hardware Interface' : 'VLAN Interface',
          name: props.bridgeName,
          configuredIn: interfaceMember.user_defined_name,
        });
      }
    });

    forEach(qosClasses, (qosClass) => {
      if (qosClass.interface === props.bridgeId) {
        mappedBridges.push({
          type: 'Qos Class',
          name: props.bridgeName,
          configuredIn: qosClass.class_name,
        });
      }
    });

    forEach(trafficSteerings, (trafficSteering) => {
      if (trafficSteering.input_interface === props.bridgeId) {
        mappedBridges.push({
          type: 'Traffic Steering-LAN',
          name: props.bridgeName,
          configuredIn: trafficSteering.name,
          configuredInterfaces: 'Input LAN',
        });
      } else if (
        trafficSteering.wan_interface === props.bridgeId ||
        trafficSteering.backup_wan_interface === props.bridgeId ||
        trafficSteering.primary_wan_1_interface === props.bridgeId ||
        trafficSteering.primary_wan_2_interface === props.bridgeId ||
        trafficSteering.backup_wan_1_interface === props.bridgeId ||
        trafficSteering.backup_wan_2_interface === props.bridgeId
      ) {
        const configuredInterfaces = Object.keys(WAN_INTERFACES)
          .map((wanInterface) => {
            if (trafficSteering[wanInterface] === props.bridgeId) {
              return WAN_INTERFACES[wanInterface];
            }
          })
          .filter((item) => item !== undefined)
          .join(',');
        mappedBridges.push({
          type: 'Traffic Steering-WAN',
          name: props.bridgeName,
          configuredIn: trafficSteering.name,
          configuredInterfaces: configuredInterfaces,
        });
      }
    });
    return mappedBridges;
  }
);

export const getMappedVlan = createSelector(
  getCurrentBridgeInterfaces,
  getCurrentDeviceQoSClasses,
  getCurrentTrafficSteering,
  (bridgeInterfaces, qosClasses, trafficSteerings, props) => {
    const mappedVlans: any[] = [];
    forEach(bridgeInterfaces, (bridgeInterface) => {
      bridgeInterface.member_ids.forEach((memberId: string) => {
        if (memberId === props.memberId) {
          mappedVlans.push({
            type: 'Bridge',
            name: props.vlanName,
            configuredIn: bridgeInterface.user_defined_name,
          });
        }
      });
    });

    forEach(qosClasses, (qosClass) => {
      if (qosClass.interface === props.memberId) {
        mappedVlans.push({
          type: 'Qos Class',
          name: props.vlanName,
          configuredIn: qosClass.class_name,
        });
      }
    });

    forEach(trafficSteerings, (trafficSteering) => {
      if (trafficSteering.input_interface === props.memberId) {
        mappedVlans.push({
          type: 'Traffic Steering-LAN',
          name: props.vlanName,
          configuredIn: trafficSteering.name,
          configuredInterfaces: 'Input LAN',
        });
      } else if (
        trafficSteering.wan_interface === props.memberId ||
        trafficSteering.backup_wan_interface === props.memberId ||
        trafficSteering.primary_wan_1_interface === props.memberId ||
        trafficSteering.primary_wan_2_interface === props.memberId ||
        trafficSteering.backup_wan_1_interface === props.memberId ||
        trafficSteering.backup_wan_2_interface === props.memberId
      ) {
        const configuredInterfaces = Object.keys(WAN_INTERFACES)
          .map((wanInterface) => {
            if (trafficSteering[wanInterface] === props.memberId) {
              return WAN_INTERFACES[wanInterface];
            }
          })
          .filter((item) => item !== undefined)
          .join(',');
        mappedVlans.push({
          type: 'Traffic Steering-WAN',
          name: props.vlanName,
          configuredIn: trafficSteering.name,
          configuredInterfaces: configuredInterfaces,
        });
      }
    });
    return mappedVlans;
  }
);
