import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SharedService } from '@shared/services/shared.service';
import { StoreState } from '@store/store';
import { AuthService } from '@views/public/auth/services/auth.service';
import { SignOut } from '@views/public/store/actions/auth.action';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export interface Error {
  hasValidationError: boolean;
  message: string;
  errorList?: string[];
  status?: number;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<void> = new Subject<void>();

  errorResponse: Error = {
    hasValidationError: false,
    message: 'Something went wrong. Please contact admin',
    status: 0,
  };

  constructor(
    private store: Store<StoreState['auth']>,
    private router: Router,
    private authService: AuthService,
    private toast: ToastrService,
    private sharedService: SharedService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorObj = typeof error.error == 'string' ? JSON.parse(error.error) : error.error;

        this.errorResponse = {
          hasValidationError: false,
          message: 'Something went wrong. Please contact admin',
          status: 0,
        };

        if (error.status <= 0) {
          this.errorResponse.message =
            'Sorry, no Internet connectivity, Please reconnect and try again';
        } else if (error.status === 422) {
          this.errorResponse.hasValidationError = true;
          this.errorResponse.errorList = errorObj.errors;
          this.errorResponse.message = 'Validation errors';
        } else if (error.status === 400) {
          this.errorResponse.errorList = errorObj.errors;
          this.errorResponse.message = 'Bad request';
        } else if (error.status === 401) {
          return this.handleUnAuthorizedResponse(request, next);
        } else if (error.status === 403) {
          this.errorResponse.message = 'You are not permitted to perform this action';
        } else if (error.status === 404) {
          this.errorResponse.message =
            errorObj.errors && errorObj.errors[0]
              ? errorObj.errors[0]
              : 'Something went wrong. Please contact admin';
        }

        return throwError(this.errorResponse);
      })
    );
  }

  handleUnAuthorizedResponse(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.endsWith('refresh_token')) {
      this.refreshTokenInProgress = false;
      this.store.dispatch(new SignOut());
      this.router.navigate(['/signin']);
      this.errorResponse.message =
        'Sorry for the inconvenience, but you are not authorized to look into this page';
      this.toast.error(this.errorResponse.message);
      return throwError(this.errorResponse);
    } else if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        switchMap(() => {
          return next.handle(request);
        })
      );
    } else {
      this.refreshTokenInProgress = true;
      return this.authService.refreshToken().pipe(
        switchMap((response) => {
          const authToken = this.sharedService.updateUserAndRefreshToken(response);
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next();
          return next.handle(
            request.clone({
              setHeaders: {
                Authorization: authToken,
              },
            })
          );
        })
      );
    }
  }
}
