import { Action } from '@ngrx/store';
import { SNMP, SNMPConfiguration } from '../models/snmp.model';

export enum ActionTypes {
  SET_SNMP_CONFIGURATION = 'SET_SNMP_CONFIGURATION',
  UPDATE_SNMP_CONFIGURATION = 'UPDATE_SNMP_CONFIGURATION',
}

export class SetSNMPConfiguration implements Action {
  readonly type = ActionTypes.SET_SNMP_CONFIGURATION;
  constructor(public payload: SNMPConfiguration) {}
}

export class UpdateSNMPConfiguration implements Action {
  readonly type = ActionTypes.UPDATE_SNMP_CONFIGURATION;
  constructor(public payload: SNMP) {}
}

export type Actions = SetSNMPConfiguration | UpdateSNMPConfiguration;
