import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@views/public/auth/services/auth.service';
import { environment } from 'environments/environment';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { StoreState } from '@store/store';
import { getAuthFeatureState } from '@views/public/store/selectors/auth.selector';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  private subscriptions = new Subscription();

  constructor(
    private store: Store<StoreState['auth']>,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.authToken();

    if (authToken && request.url.startsWith(environment.baseUrl)) {
      if (request.url.endsWith('refresh_token')) {
        let refreshToken: string;
        this.subscriptions.add(
          this.store.select(getAuthFeatureState).subscribe((authState) => {
            refreshToken = authState.refresh_token;
          })
        );
        request = request.clone({
          setHeaders: {
            'Refresh-Token': `${refreshToken}`,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `${authToken}`,
          },
        });
      }
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.subscriptions.unsubscribe();
      })
    );
  }
}
