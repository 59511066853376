import { Injectable } from '@angular/core';
import { NotificationEventResponse } from '@views/secure/notifications/store/notifications.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  hasUpdatedNotification = new Subject<void>();

  private getEventSource(url: string): EventSource {
    const options: EventSourceInit = {
      withCredentials: false,
    };
    return new EventSource(url, options);
  }

  getServerSentEvent(url: string): Observable<NotificationEventResponse> {
    const eventSource = this.getEventSource(url);

    return new Observable<NotificationEventResponse>((subscriber) => {
      eventSource.addEventListener('notification', (event: MessageEvent<string>) => {
        subscriber.next(<NotificationEventResponse>JSON.parse(event.data));
        this.hasUpdatedNotification.next();
      });

      eventSource.onerror = (error) => {
        subscriber.error(error);
      };

      return function unsubscribe(): void {
        eventSource.close();
      };
    });
  }
}
