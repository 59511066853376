import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceReducer } from '@secure/devices/store/models/devices.model';

const getDeviceFeatureState = createFeatureSelector<DeviceReducer>('devices');

export const getEditDevice = createSelector(getDeviceFeatureState, (state) => state.editDevice);

export const getCurrentDeviceOrganizationDetails = createSelector(
  getDeviceFeatureState,
  (state) => state.organizationOfTheDevice
);

export const getCurrentDeviceBasicDetails = createSelector(
  getDeviceFeatureState,
  (state) => state.deviceBasicDetails
);

export const getPreviousDeviceDetails = createSelector(
  getDeviceFeatureState,
  (state) => state.previousDeviceDetails
);
