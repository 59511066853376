import { DeviceReducer } from '@secure/devices/store/models/devices.model';
import {
  DEVICE_SOFTWARE_UPDATE_WEB_SOCKET,
  DEVICE_STATE,
  DEVICE_WEB_SOCKET_CONNECTION,
} from '@shared/constants/constants';
import { chain } from 'lodash';
import { Actions, ActionTypes } from '../actions/devices.action';

export const initialState: DeviceReducer = {
  columns: {
    name: '',
    uuid: '',
    is_online: '',
  },
  hasFloatingFilter: false,
  globalSearch: '',
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  selectedRows: [],
  shuffledColumns: [
    {
      key: 'Location',
      field: 'location',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 200,
    },
    { key: 'Name', field: 'name', hide: false, sort: '', minWidth: 75, width: 190 },
    { key: 'UUID', field: 'uuid', hide: false, sort: '', minWidth: 104, width: 104 },
    {
      key: 'Device Status',
      field: 'is_online',
      hide: false,
      sort: '',
      minWidth: 155,
      width: 155,
    },
    { key: 'Type', field: 'type', hide: false, sort: '', minWidth: 100, width: 200 },
    {
      key: 'Organization',
      field: 'organization.name',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 200,
    },
    {
      key: 'Status',
      field: 'state',
      hide: false,
      sort: '',
      minWidth: 112,
      width: 112,
    },
    {
      key: 'Version',
      field: 'package_version',
      hide: false,
      sort: '',
      minWidth: 112,
      width: 112,
    },
  ],
  devices: [],
  editDevice: {
    name: '',
    uuid: '',
    type: null,
    device_model_id: null,
    organization_id: 0,
    location: '',
    latitude: 0,
    longitude: 0,
  },
  organizationOfTheDevice: {
    id: 0,
    name: '',
    description: '',
    uuid: '',
    client_name: '',
    client_email: '',
    client_mobile_no: '',
    client_address: '',
    devices_count: 0,
    provisioned_devices_count: 0,
    created_by: 0,
    updated_by: 0,
    created_at: '',
    updated_at: '',
  },
  deviceBasicDetails: {
    name: '',
    uuid: '',
    location: '',
    latitude: 0,
    longitude: 0,
    organizationName: '',
    organizationId: 0,
  },
  previousDeviceDetails: {
    name: '',
    location: '',
    incharge_email: '',
    is_updates_available: false,
  },
};

export function devicesReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.FETCH_DEVICES:
      const deviceList = action.payload.devices.map((d) => {
        return {
          ...d,
          is_online: d.is_online
            ? DEVICE_WEB_SOCKET_CONNECTION.ACTIVE
            : DEVICE_WEB_SOCKET_CONNECTION.IN_ACTIVE,
          package_version: d.package_version ? d.package_version : 'NA',
          is_system_upgrade_online: d.is_system_upgrade_online
            ? DEVICE_SOFTWARE_UPDATE_WEB_SOCKET.ACTIVE
            : DEVICE_SOFTWARE_UPDATE_WEB_SOCKET.IN_ACTIVE,
        };
      });
      return {
        ...state,
        devices: deviceList,
        pagination: action.payload.pagination,
      };
    case ActionTypes.UPDATE_CURRENT_PAGE:
      state.pagination.current_page = action.payload;
      return {
        ...state,
      };
    case ActionTypes.GLOBAL_SEARCH:
      return {
        ...state,
        globalSearch: action.payload,
      };
    case ActionTypes.CLEAR_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [],
      };
    case ActionTypes.COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.COLUMN_SEARCH:
      const key = Object.keys(action.payload)[0];
      state.columns[key.toString()] = Object.values(action.payload)[0];
      return {
        ...state,
      };
    case ActionTypes.UPDATE_FLOATING_FILTER:
      return {
        ...state,
        hasFloatingFilter: action.payload,
      };
    case ActionTypes.CLEAR_COLUMNS_SEARCH:
      return {
        ...state,
        columns: { ...initialState.columns },
      };
    case ActionTypes.CLEAR_COLUMNS_SORT:
      state.shuffledColumns.forEach((data) => {
        data.sort = '';
      });
      return {
        ...state,
      };
    case ActionTypes.UPDATE_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [...action.payload],
      };
    case ActionTypes.UPDATE_COLUMNS_SORT:
      state.shuffledColumns.forEach((column) => {
        let fieldMatch = false;

        action.payload.forEach((data) => {
          if (column.field === data.colId) {
            fieldMatch = true;
            column.sort = data.sort;
          }
        });

        if (!fieldMatch) {
          column.sort = '';
        }
      });

      return {
        ...state,
      };
    case ActionTypes.UPDATE_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });

      return {
        ...state,
      };
    case ActionTypes.DELETE_DEVICES:
      const modifiedData = chain(state.devices)
        .map((device) => {
          if (action.payload.includes(device.id) && device.provisioned_at) {
            device.state = DEVICE_STATE.BEING_DELETED;
          } else if (action.payload.includes(device.id) && !device.provisioned_at) {
            return null;
          }
          return device;
        })
        .compact()
        .value();
      return {
        ...state,
        devices: [...modifiedData],
      };
    case ActionTypes.SET_EDIT_DEVICE:
      return {
        ...state,
        editDevice: action.payload,
      };
    case ActionTypes.DEVICE_PACKAGE_UPGRADE:
      const upgradedData = chain(state.devices)
        .map((device) => {
          if (action.payload.includes(device.id)) {
            device.state = DEVICE_STATE.BEING_UPDATED;
          }
          return device;
        })
        .compact()
        .value();
      return {
        ...state,
        devices: [...upgradedData],
      };
    case ActionTypes.SET_ORGANIZATION_OF_THE_DEVICE:
      return {
        ...state,
        organizationOfTheDevice: action.payload,
      };
    case ActionTypes.SET_DEVICE_BASIC_DETAILS:
      return {
        ...state,
        deviceBasicDetails: action.payload,
      };
    case ActionTypes.SET_PREVIOUS_DEVICE_DETAILS:
      return {
        ...state,
        previousDeviceDetails: action.payload,
      };
    default:
      return state;
  }
}
