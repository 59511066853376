import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Columns, DeleteResponse } from '@shared/models/shared.model';
import { SseService } from '@shared/services/sse.service';
import { StoreState } from '@store/store';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FetchNotifications } from '../store/notifications.actions';
import {
  DateRange,
  EventType,
  MarkAllAsReadResponse,
  MarkAsReadParam,
  Notification,
  NotificationColumnSearch,
  NotificationDetailResponse,
  NotificationEventResponse,
  NotificationsResponse,
} from '../store/notifications.model';
import { getNotificationsFeatureState } from '../store/notifications.selector';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly apiUrl = environment.baseUrl;
  private page: number;
  private globalSearch: string;
  private eventType: string;
  private sortedColumns: Columns[];
  private columnSearch: NotificationColumnSearch;
  private hasFloatingFilter: boolean;
  private selectedDateRange: DateRange;

  constructor(
    private store: Store<StoreState>,
    private http: HttpClient,
    private toast: ToastrService,
    private sseService: SseService
  ) {
    this.store.select(getNotificationsFeatureState).subscribe((val) => {
      this.page = val.pagination.current_page;
      this.globalSearch = val.globalSearch;
      this.sortedColumns = val.shuffledColumns;
      this.eventType = val.activeTab;
      this.columnSearch = val.columns;
      this.hasFloatingFilter = val.hasFloatingFilter;
      this.selectedDateRange = val.selectedDateRange;
    });
  }

  private getRequestParams(hasPagination: boolean): HttpParams {
    let params = new HttpParams();
    if (hasPagination) {
      params = params.append('page', this.page.toString());
    }
    params = params.append('q', this.globalSearch);
    params = params.append('name', this.eventType ? this.eventType : '');
    params = params.append('from_date', this.selectedDateRange.startDate);
    params = params.append('to_date', this.selectedDateRange.endDate);

    if (this.hasFloatingFilter) {
      Object.keys(this.columnSearch).forEach((key) => {
        const value = this.columnSearch[key];
        if (value && value != '') {
          params = params.append(key, this.columnSearch[key]);
        }
      });
    }

    const visibleColumns: string[] = [];

    this.sortedColumns.forEach((column: Columns) => {
      if (!column.hide) {
        visibleColumns.push(column.field);
      }
      if (column.sort !== '') {
        params = params.append(`o_${column.field}`, column.sort.toUpperCase());
      }
    });

    params = params.append('visible_columns', visibleColumns.toString());
    return params;
  }

  fetchNotifications(): void {
    const params = this.getRequestParams(true);
    this.http.get<NotificationsResponse>(`${this.apiUrl}/v1/events`, { params }).subscribe(
      (data) => {
        const notificationsResponse: NotificationsResponse = {
          events: data.events,
          pagination: data.pagination,
          unread_counts: data.unread_counts,
        };
        this.store.dispatch(new FetchNotifications(notificationsResponse));
      },
      (error) => {
        this.toast.error(error.message);
      }
    );
  }

  markAllAsRead(eventType?: EventType | undefined): Observable<MarkAllAsReadResponse> {
    let params: MarkAsReadParam = {
      event_type: eventType ? eventType : null,
    };
    return this.http.post<MarkAllAsReadResponse>(`${this.apiUrl}/v1/events/mark_all_read`, params);
  }

  markAsRead(notificationId: number | string): Observable<Notification> {
    return this.http.put<Notification>(
      `${this.apiUrl}/v1/events/mark_read/${notificationId}`,
      null
    );
  }

  markAsUnread(notificationId: number): Observable<Notification> {
    return this.http.put<Notification>(
      `${this.apiUrl}/v1/events/mark_unread/${notificationId}`,
      null
    );
  }

  delete(idsToDelete: string[]): Observable<DeleteResponse> {
    const params = {
      ids: idsToDelete.toString(),
    };
    return this.http.delete<DeleteResponse>(`${this.apiUrl}/v1/events`, { params });
  }

  getLiveNotifications(userToken: string): Observable<NotificationEventResponse> {
    return this.sseService.getServerSentEvent(`${this.apiUrl}/v1/sse?token=${userToken}`);
  }

  getNotificationDetailById(notification_id: number): Observable<NotificationDetailResponse> {
    return this.http.get<NotificationDetailResponse>(`${this.apiUrl}/v1/events/${notification_id}`);
  }

  exportCsvFile(): Observable<string> {
    const params = this.getRequestParams(false);
    return this.http.get(`${this.apiUrl}/v1/events/download`, {
      params,
      responseType: 'text',
    });
  }
}
