import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SharedService } from '@shared/services/shared.service';
import { AuthService } from '@views/public/auth/services/auth.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-grafana-iframe',
  templateUrl: './grafana-iframe.component.html',
  styleUrls: ['./grafana-iframe.component.scss'],
})
export class GrafanaIframeComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  iframeUrl: SafeUrl;
  private _panelUrl: string;
  private token: string;
  private readonly grfanaBaseUrl = environment.grafanaBaseUrl;
  private tokenRefreshTimeout: ReturnType<typeof setTimeout>;
  private subscriptions = new Subscription();

  @Input() set panelUrl(url: string) {
    this.isLoading = true;
    this._panelUrl = url;
    this.updateIframeUrl();
  }
  @Input() refresh = '10s';
  @Input() panelHeight: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  public ngOnInit(): void {
    this.token = this.authService.authToken()?.replace('Bearer ', '');
    this.getTokenExpiryTime(this.token);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateIframeUrl(): void {
    if (this.token && this._panelUrl) {
      const url = encodeURI(
        `${this.grfanaBaseUrl}/${this._panelUrl}&orgId=1&refresh=${this.refresh}&var-grafana_base_url=${this.grfanaBaseUrl}&var-user_token=${this.token}&token=${this.token}`
      );
      this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  public onIframeLoad(): void {
    setTimeout(() => (this.isLoading = false), 1500);
  }

  private getTokenExpiryTime(token: string): void {
    const timeUntilRefresh = this.sharedService.getTokenExpiryTime(token);

    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
    }

    if (timeUntilRefresh > 0) {
      this.tokenRefreshTimeout = setTimeout(() => {
        this.getNewAuthToken();
      }, timeUntilRefresh);
    } else {
      this.getNewAuthToken();
    }
  }

  private getNewAuthToken(): void {
    const observer = this.authService.refreshToken().subscribe((response) => {
      const authToken = this.sharedService.updateUserAndRefreshToken(response);
      this.token = authToken.replace('Bearer', '').trim();
      this.updateIframeUrl();
      this.getTokenExpiryTime(this.token);
    });
    this.subscriptions.add(observer);
  }
}
