import { HttpClient, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ROLES } from '@shared/constants/constants';
import { OrganizationList, RoleList } from '@shared/models/shared.model';
import { UpdateAuthToken, UpdateRefreshToken } from '@views/public/store/actions/auth.action';
import { getUserRole, isSuperAdmin } from '@views/public/store/selectors/auth.selector';
import { environment } from 'environments/environment';
import { camelCase, mapKeys, snakeCase } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  onDeviceNameChange = new EventEmitter<string>();
  private readonly apiUrl = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {}

  getOrganizations(): Observable<OrganizationList[]> {
    return this.httpClient.get<OrganizationList[]>(`${this.apiUrl}/v1/organizations/list`);
  }

  getRoles(): RoleList[] {
    return Object.keys(ROLES).map((role) => {
      return { name: ROLES[role], value: ROLES[role] };
    });
  }

  get role(): ROLES {
    let role: ROLES;
    this.store.pipe(select(getUserRole), take(1)).subscribe((value) => {
      role = value;
    });
    return role;
  }

  get isSuperAdmin(): boolean {
    let superAdmin: boolean;
    this.store.pipe(select(isSuperAdmin), take(1)).subscribe((isSuper: boolean) => {
      superAdmin = isSuper;
    });
    return superAdmin;
  }

  camelToSnakeCase(object: Object) {
    return mapKeys(object, (_value: any, key: string) => snakeCase(key));
  }

  snakeToCamelCase(object: Object) {
    return mapKeys(object, (_value: any, key: string) => camelCase(key));
  }

  updateUserAndRefreshToken(response: HttpResponse<string>): string {
    const newAuthToken = response.headers.get('Authorization');
    this.store.dispatch(new UpdateAuthToken(newAuthToken));

    const newRefreshToken = response.headers.get('Refresh-Token');
    this.store.dispatch(new UpdateRefreshToken(newRefreshToken));

    return newAuthToken;
  }

  getTokenExpiryTime(token: string): number {
    const payload = token.split('.')[1];
    const decodedPayload = atob(payload);
    const tokenExpiryTime = JSON.parse(decodedPayload).exp;

    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilRefresh = (tokenExpiryTime - currentTime - 60) * 1000;

    return timeUntilRefresh;
  }
}
