import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Address4 } from 'ip-address';
import { find, isUndefined, pick, uniq } from 'lodash';
import { DeviceConfigurationReducer } from '../models/device-configuration.model';
import { getCurrentDeviceBridgeInterfaces } from './bridge-interface-configuration.selector';
import { getCurrentDeviceHardwareInterfaces } from './hardware-interface-configuration.selector';
import { getQoSFeatureState } from './qos-configuration.selector';
import { getTrafficSteeringConfigurationFeatureState } from './traffic-steering-configuration.selector';
import { getCurrentDeviceTunnelInterfaces } from './tunnel-interface-configuration.selector';
import { getCurrentDeviceVlanInterfaces } from './vlan-interface-configuration.selector';

const getDeviceConfigurationFeatureState =
  createFeatureSelector<DeviceConfigurationReducer>('deviceConfiguration');

export const getCurrentConfigComment = createSelector(
  getDeviceConfigurationFeatureState,
  (state) => state.comment
);

export const getLocallyChangedFlag = createSelector(
  getDeviceConfigurationFeatureState,
  (state) => state.isLocallyChanged
);

export const getCurrentDeviceConfigVersion = createSelector(
  getDeviceConfigurationFeatureState,
  (state) => state.version
);

export const getDeviceVersionChanged = createSelector(
  getDeviceConfigurationFeatureState,
  (state) => state.isVersionChanged
);

export const getDeviceStateAndOnlineStatus = createSelector(
  getDeviceConfigurationFeatureState,
  (state) => {
    const deviceStateAndOnlineStatus = {
      isDeviceOnline: state.is_device_online,
      deviceState: state.device_state,
    };
    return deviceStateAndOnlineStatus;
  }
);

export const getDeletedQosClasses = createSelector(
  getTrafficSteeringConfigurationFeatureState,
  getQoSFeatureState,
  (trafficSteering, qosClass) => {
    const deletedQoSIds: string[] = [];
    trafficSteering.traffic_steering
      .filter((item) => item.qos_id || item.input_qos_id || item.backup_qos_id)
      .forEach((trafficSteeringData) => {
        if (trafficSteeringData.qos_id) {
          const selectedQoS = find(qosClass.qosClasses, { id: trafficSteeringData.qos_id });
          if (isUndefined(selectedQoS)) {
            deletedQoSIds.push(trafficSteeringData.qos_id);
          }
        }
        if (trafficSteeringData.input_qos_id) {
          const selectedQoS = find(qosClass.qosClasses, { id: trafficSteeringData.input_qos_id });
          if (isUndefined(selectedQoS)) {
            deletedQoSIds.push(trafficSteeringData.input_qos_id);
          }
        }
        if (trafficSteeringData.backup_qos_id) {
          const selectedQoS = find(qosClass.qosClasses, { id: trafficSteeringData.backup_qos_id });
          if (isUndefined(selectedQoS)) {
            deletedQoSIds.push(trafficSteeringData.backup_qos_id);
          }
        }
      });
    return deletedQoSIds;
  }
);

export const getQoSClassLeafNodes = createSelector(
  getTrafficSteeringConfigurationFeatureState,
  getQoSFeatureState,
  (trafficSteering, qosClass) => {
    const leafNodes: string[] = [];
    trafficSteering.traffic_steering
      .filter((item) => item.qos_id || item.input_qos_id || item.backup_qos_id)
      .forEach((trafficSteeringData) => {
        if (trafficSteeringData.qos_id) {
          const leafNode = find(qosClass.qosClasses, { parent_id: trafficSteeringData.qos_id });
          if (!isUndefined(leafNode)) {
            leafNodes.push(trafficSteeringData.qos_id);
          }
        }
        if (trafficSteeringData.input_qos_id) {
          const leafNode = find(qosClass.qosClasses, {
            parent_id: trafficSteeringData.input_qos_id,
          });
          if (!isUndefined(leafNode)) {
            leafNodes.push(trafficSteeringData.input_qos_id);
          }
        }
        if (trafficSteeringData.backup_qos_id) {
          const leafNode = find(qosClass.qosClasses, {
            parent_id: trafficSteeringData.backup_qos_id,
          });
          if (!isUndefined(leafNode)) {
            leafNodes.push(trafficSteeringData.backup_qos_id);
          }
        }
      });
    return uniq(leafNodes);
  }
);

export const getAllMatchingIPAddresses = createSelector(
  getCurrentDeviceHardwareInterfaces,
  getCurrentDeviceTunnelInterfaces,
  getCurrentDeviceVlanInterfaces,
  getCurrentDeviceBridgeInterfaces,
  (hardwareInterfaces, tunnelInterfaces, vlanInterfaces, bridgeInterfaces, props) => {
    const allInterfaces = [
      ...hardwareInterfaces,
      ...tunnelInterfaces,
      ...vlanInterfaces,
      ...bridgeInterfaces,
    ];
    const addressInd = new Address4(props.gatewayIP);

    const allMatchingIPAddresses = allInterfaces
      .filter(function (hwInt) {
        const isInSubnetArray = hwInt.system_defined_ip_addresses?.filter(
          function (currentInterface) {
            const currentInterfaceIPAdd4 = new Address4(currentInterface);
            return addressInd.isInSubnet(currentInterfaceIPAdd4);
          }
        );
        return isInSubnetArray && isInSubnetArray.length > 0;
      })
      .map((obj) => ({
        display_name: `${obj.user_defined_name}-${obj.system_defined_name}`,
        system_defined_name: obj.system_defined_name,
        network_side: obj.network_side,
      }));

    return allMatchingIPAddresses;
  }
);

export const getAllWanInterfaces = createSelector(
  getCurrentDeviceHardwareInterfaces,
  getCurrentDeviceTunnelInterfaces,
  getCurrentDeviceVlanInterfaces,
  getCurrentDeviceBridgeInterfaces,
  (hardwareInterfaces, tunnelInterfaces, vlanInterfaces, bridgeInterfaces) => {
    const allInterfaces = [
      ...hardwareInterfaces,
      ...tunnelInterfaces,
      ...vlanInterfaces,
      ...bridgeInterfaces,
    ];

    const allWanInterfaces = allInterfaces
      .filter(function (currentInterface) {
        return currentInterface.network_side === 'WAN';
      })
      .map((obj) => pick(obj, ['user_defined_name', 'id']));

    return allWanInterfaces;
  }
);
