import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrafficSteeringConfigurationReducer } from '../models/traffic-steering-configuration.model';

export const getTrafficSteeringConfigurationFeatureState =
  createFeatureSelector<TrafficSteeringConfigurationReducer>('trafficSteeringConfiguration');

export const getCurrentTrafficSteering = createSelector(
  getTrafficSteeringConfigurationFeatureState,
  (state) => state.traffic_steering
);
