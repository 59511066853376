import { ActionTypes, Actions } from '../actions/snmp.action';
import { SNMPConfiguration } from '../models/snmp.model';

export const initialState: SNMPConfiguration = {
  id: 0,
  device_id: 0,
  snmp: {
    status: false,
  },
  current_version: 0,
  current_active_version: 0,
  is_updates_available: false,
  updates_available_from: '',
  updates_synced_at: '',
  is_configuration_file_generated: false,
  created_at: '',
  updated_at: '',
};

export function snmpReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_SNMP_CONFIGURATION:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.UPDATE_SNMP_CONFIGURATION:
      return {
        ...state,
        snmp: {
          ...state.snmp,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
