import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { INavData } from '@coreui/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@public/auth/services/auth.service';
import { Permissions } from '@shared/constants/permission.constant';
import { Error } from '@shared/interceptors/http-error.interceptor';
import { GlobalLanguageService } from '@shared/services/global-language.service';
import { HelperService } from '@shared/services/helper.service';
import { SharedService } from '@shared/services/shared.service';
import { StoreState } from '@store/store';
import { SignOut } from '@views/public/store/actions/auth.action';
import { UserDetails } from '@views/public/store/models/auth.model';
import {
  getAuthToken,
  getOrganizationName,
  getUserDetail,
  isSuperAdmin,
} from '@views/public/store/selectors/auth.selector';
import { NotificationsService } from '@views/secure/notifications/service/notifications.service';
import {
  NotificationEvent,
  NotificationEventResponse,
  NotificationEventTypes,
} from '@views/secure/notifications/store/notifications.model';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { navItems } from '../../_nav';
import { NavData } from './default-layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  public sidebarMinimized = false;
  public navItems: INavData[] = navItems.filter((items) => {
    this.translate(items);
    return this.roleBasedNav(items);
  });
  userData: UserDetails;
  hasPageHelp: boolean;
  organizationName: string;
  notificationCount: number;
  notifications: NotificationEvent[];
  notificationPermission = Permissions.notifications;
  isSuperAdmin: boolean;
  private allowToPropagate: boolean;
  private userToken: string;
  private subscriptions = new Subscription();
  notificationObservable: Subscription;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private toast: ToastrService,
    private globalTranslate: GlobalLanguageService,
    private store: Store<StoreState['auth']>,
    private authService: AuthService,
    private helperService: HelperService,
    private sharedService: SharedService,
    private notificationService: NotificationsService
  ) {
    const observer = this.store.select(getAuthToken).subscribe((token) => {
      this.userToken = token?.replace('Bearer ', '');
    });
    this.subscriptions.add(observer);
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(isSuperAdmin)
        .subscribe((isSuperAdmin: boolean) => (this.isSuperAdmin = isSuperAdmin))
    );
    const languageObserver = this.translateService.onLangChange.subscribe(() => {
      this.updateNavItems();
    });
    this.subscriptions.add(languageObserver);

    const authObserver = this.store.select(getUserDetail).subscribe((data: UserDetails) => {
      this.userData = data;
      this.updateNavItems();
    });
    this.subscriptions.add(authObserver);

    const pageHelperObserver = this.helperService.onPageHelpChange.subscribe(
      (hasView) => (this.hasPageHelp = hasView)
    );
    this.subscriptions.add(pageHelperObserver);

    this.getOrganizationName();
    if (!this.isSuperAdmin) {
      this.getNotifications();
    }
  }

  private getNotifications(): void {
    this.notificationObservable = this.notificationService
      .getLiveNotifications(this.userToken)
      .subscribe({
        next: (data: NotificationEventResponse) => {
          this.notificationCount = data.event_count;
          this.notifications = data.events;
        },
        error: () => {
          this.notificationObservable.unsubscribe();
          const timeUntilRefresh = this.sharedService.getTokenExpiryTime(this.userToken);
          if (timeUntilRefresh <= 0) {
            this.getNewAuthToken();
            this.getNotifications();
          } else {
            setTimeout(() => {
              this.getNotifications();
            }, 5000);
          }
        },
      });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateNavItems(): void {
    const translatedNavs = this.navItems.map((items) => {
      if (items.name === 'Dashboard') {
        items.url = `/dashboard/${this.userData.organizationId}`;
      }
      this.translate(items);
      return items;
    });
    this.navItems = [...translatedNavs];
  }

  public getOrganizationName(): void {
    const observer = this.store.select(getOrganizationName).subscribe((val) => {
      if (val) {
        this.organizationName = val;
      }
    });
    this.subscriptions.add(observer);
  }

  toggleMinimize(e: boolean): void {
    this.sidebarMinimized = e;
  }

  translate(item: NavData): void {
    if ('key' in item) {
      const trans = this.translateService.instant(`${item.key}`);

      if (trans !== `${item.key}`) {
        item.name = trans;
      }
    }
    if (item.children && item.children.length > 0) {
      item.children.forEach((child: any) => this.translate(child));
    }
  }

  signOutUser(): void {
    const signOutObserver = this.authService.signOut().subscribe(
      () => {
        this.clearUserCredentials();
      },
      () => {
        this.clearUserCredentials();
      }
    );
    this.subscriptions.add(signOutObserver);
  }

  clearUserCredentials(): void {
    const message = this.globalTranslate.getTranslation('AUTH.SIGNOUT');
    this.store.dispatch(new SignOut());
    this.router.navigate(['/signin']);
    this.toast.success(message);
  }

  onClickPageHelp(): void {
    this.helperService.togglePageHelpView();
  }

  public getNotificationIconLink(notificationType: NotificationEventTypes): string {
    switch (notificationType) {
      case NotificationEventTypes.device:
        return '/assets/img/icons/network.svg';
      case NotificationEventTypes.user:
        return '/assets/img/icons/user_tick.svg';
      default:
        return '/assets/img/icons/user_tick.svg';
    }
  }

  public onDropDownMenuClick(event: Event): void {
    if (!this.allowToPropagate) {
      event.stopPropagation();
    }
    this.allowToPropagate = false;
  }

  setAllowToPropagate(): void {
    this.allowToPropagate = true;
  }

  public getRelativeTime(date: string): string {
    return moment(date).fromNow();
  }

  public onMarkAllAsRead(): void {
    const observer = this.notificationService.markAllAsRead().subscribe(
      () => {
        const message = this.globalTranslate.getTranslation(
          'NOTIFICATIONS.NOTIFICATIONS.MARKED_AS_READ'
        );
        this.toast.success(message);
      },
      (error: Error) => {
        this.toast.error(error.message);
      }
    );
    this.subscriptions.add(observer);
  }

  public onMarkAsRead(notification: NotificationEvent): void {
    if (!notification.has_read) {
      const observer = this.notificationService.markAsRead(notification.id).subscribe();
      this.subscriptions.add(observer);
    }
  }

  private roleBasedNav(navItem: NavData): boolean {
    return !navItem?.roles || navItem?.roles?.includes(this.sharedService.role);
  }

  private getNewAuthToken(): void {
    const observer = this.authService.refreshToken().subscribe((response) => {
      this.sharedService.updateUserAndRefreshToken(response);
    });
    this.subscriptions.add(observer);
  }
}
